

export const WelcomePartyBottomBar = ({}) => {
    return <div style={{
        background: 'white',
        height: 80,
        width: '100%',
        borderTop: '1px solid rgb(227, 225, 236)'
    }} className="bottom-bar">

    </div>
}

export default WelcomePartyBottomBar