import React from 'react';

export function generateAvatarUrl(user) {
    if(!user) {
        return null
    }
    if(!user.discord_avatar_hash) {
        return null
    }
    return `https://cdn.discordapp.com/avatars/${user.discord_user_id}/${user.discord_avatar_hash}`
}

export function generateAvatarImage(user) {
    const url = generateAvatarUrl(user)

    if(!url) {
        return <div style={{ width: '100%', height: '100%' }}>
        </div>
    }

    return <img src={url} alt="user-image" style={{ borderRadius: '50%', maxWidth: '100%', maxHeight:'100%'}}/>
}