import { useEffect, useState } from "react";
import axios from '../../../utils/axios';
import SearchableDropdown from '../../SearchableDropdown'

import IconCheckmarkSuccess from "../../../icons/checkmark-success.png"
import IconVideo from "../../../icons/video.png"

import ReactInterval from 'react-interval';

import { BallTriangle } from  'react-loader-spinner'

const FAQ_LINKS = [
    {
        title: 'How can I find the channel ID?',
        url: 'https://www.landing.party/scout-install#find-id-channel'
    }
]


export const FtuxStep5SetupOnboarding = ({ onboardingObject, setOnboardingObject, currentStep, isFaq }) => {

    const [isSuccess, setIsSuccess] = useState(false)
    const [error, setError] = useState(null)

    const [allChannels, setAllChannels] = useState(null)
    const [selectedChannel, setSelectedChannel] = useState(isFaq ? [] : null)

    const communityName = onboardingObject.guildName

    const [isLoading, setIsLoading] = useState(false)

    function loadChannels() {
        axios.get(`/get_channels/${onboardingObject.guild_id}`).then(response => {
            console.log(response.data)
            setAllChannels(response.data.filter(x => x.name[0].toLowerCase() === x.name[0]))

            if(onboardingObject.channel_id) {
                const matches = response.data.filter(x => x.id === onboardingObject.channel_id)
    
                if(matches.length > 0) {
                    setSelectedChannel(matches[0])
                }
            }

            if(onboardingObject.channel_ids) {
                const matches = response.data.filter(x => onboardingObject.channel_ids.includes(x.id) )
    
                setSelectedChannel(matches)
            }
        })
    }

    useEffect(() => {
        loadChannels()

        if(!communityName) {
            axios.get("https://discord.com/api/v8/users/@me/guilds").then(response => {
                for(const guild of response.data) {
                    if(guild.id == onboardingObject.guild_id) {
                        setOnboardingObject({
                            ...onboardingObject,
                            guildName: guild.name
                        })
                        break;
                    }
                }
            })
        }
    }, [])

    function checkSuccess(channel_id) {
        if(channel_id?.channel_ids) {
            if(channel_id?.channel_ids.length > 0) {
                setIsLoading(true)
                axios.get(`/does_bot_have_channel_access/${channel_id?.channel_ids.join(',')}`).then(response => {
                    if(response.data.has_permissions) {
                        setIsSuccess(true)
                    } else {
                        setIsSuccess(false)
                        setError("Bot needs a member of all specified channels")
                    }
                    setIsLoading(false)
                })
            }
        }
        else if(channel_id) {
            setIsLoading(true)
            axios.get(isFaq ? `/does_bot_have_channel_access/${channel_id}` : `/onboarding/does_bot_have_expected_channel_permissions/${onboardingObject.id}?channel_id=${channel_id}`).then(response => {
                if(response.data.has_permissions) {
                    setIsSuccess(true)
                } else {
                    setIsSuccess(false)
                    setError(isFaq ? "Bot is not a member of this channel" : "This channel configuration blocks some permissions required by the bot")
                }
                setIsLoading(false)
            }).catch((error) => {
                if(error.response.data.error)
                    setError(error.response.data.error)
                setIsLoading(false)
            })
        }
    }

    useEffect(() => {
        if(selectedChannel) {
            if(isFaq) {
                setOnboardingObject({
                    ...onboardingObject,
                    channel_ids: selectedChannel?.map(x => x.id) || [],
                    channelLinked: false
                })
                setIsSuccess(false)
                setError(null)
                checkSuccess({ channel_ids: selectedChannel.map(x => x.id) })
            } else {
                setOnboardingObject({
                    ...onboardingObject,
                    channel_id: selectedChannel.id,
                    channelLinked: false
                })
                setIsSuccess(false)
                setError(null)
                checkSuccess(selectedChannel.id)
            }
        }
    }, [selectedChannel])

    useEffect(() => {
        setOnboardingObject({
            ...onboardingObject,
            channelLinked: isSuccess
        })
    }, [isSuccess])

    return <div style={{width: '100%', textAlign: 'left'}}>
        <ReactInterval timeout={5 * 1000} enabled={!isSuccess} callback={() => checkSuccess(onboardingObject.channel_id)} />

        <p style={{ color: '#212E47', fontSize: 12, fontWeight: 500, textAlign: 'left', lineHeight: '24px', marginBottom: 10}}>Community: <b>{communityName ? communityName : 'Loading...'}</b></p>

        <h3 style={{fontSize: 20, fontWeight: 500, textAlign: 'left', marginTop:5}}>{currentStep.stepTitle}</h3>

        <p style={{ color: '#777680', fontSize: 14, lineHeight: '20px', fontWeight: 400, textAlign: 'left', fontFamily: 'Roboto', letterSpacing: '0.25px' }}>
            {isFaq ? 'Scout will only answer questions in the channels you select.' : 'The onboarding flow will be accessible from a dedicate channel. Make sure you’ve created one, then link it below.'}
        </p>

        {isFaq ? <>
            <SearchableDropdown 
                isLoading={allChannels === null}
                items={allChannels}
                renderItem={channel => <div style={{fontSize: 14, color: '#001A40', letterSpacing: '0.15px'}}><b style={{fontWeight: 500}}>#{channel.name}</b> <span style={{color: '#91909A', fontWeight: 500}}>{channel.id}</span></div>}
                filterPredicate={(search, channel) => channel.name.toLowerCase().includes(search.toLowerCase()) || (channel.id + '').toLowerCase().includes(search.toLowerCase())}
                selectedItem={selectedChannel?.[0]}
                onChange={newChannel => setSelectedChannel([
                    newChannel,
                    selectedChannel[1],
                    selectedChannel[2]
                ].filter(x => x))}
                icon={null}
                emptyLabel="Select or type channel name"
            />
            {selectedChannel?.[0] || selectedChannel?.[1] ? <>
                <br/>
                <SearchableDropdown 
                    isLoading={allChannels === null}
                    items={allChannels}
                    renderItem={channel => <div style={{fontSize: 14, color: '#001A40', letterSpacing: '0.15px'}}><b style={{fontWeight: 500}}>#{channel.name}</b> <span style={{color: '#91909A', fontWeight: 500}}>{channel.id}</span></div>}
                    filterPredicate={(search, channel) => channel.name.toLowerCase().includes(search.toLowerCase()) || (channel.id + '').toLowerCase().includes(search.toLowerCase())}
                    selectedItem={selectedChannel?.[1]}
                    onChange={newChannel => setSelectedChannel([
                        selectedChannel[0],
                        newChannel,
                        selectedChannel[2]
                    ].filter(x => x))}
                    icon={null}
                    emptyLabel="Select or type another channel name (optional)"
                />
            </> : <></>}
            {selectedChannel?.[1] || selectedChannel?.[2] ? <>
                <br/>
                <SearchableDropdown 
                    isLoading={allChannels === null}
                    items={allChannels}
                    renderItem={channel => <div style={{fontSize: 14, color: '#001A40', letterSpacing: '0.15px'}}><b style={{fontWeight: 500}}>#{channel.name}</b> <span style={{color: '#91909A', fontWeight: 500}}>{channel.id}</span></div>}
                    filterPredicate={(search, channel) => channel.name.toLowerCase().includes(search.toLowerCase()) || (channel.id + '').toLowerCase().includes(search.toLowerCase())}
                    selectedItem={selectedChannel?.[2]}
                    onChange={newChannel => setSelectedChannel([
                        selectedChannel[0],
                        selectedChannel[1],
                        newChannel,
                    ].filter(x => x))}
                    icon={null}
                    emptyLabel="Select or type another channel name (optional)"
                />
            </> : <></>}
        </> : <SearchableDropdown 
            isLoading={allChannels === null}
            items={allChannels}
            renderItem={channel => <div style={{fontSize: 14, color: '#001A40', letterSpacing: '0.15px'}}><b style={{fontWeight: 500}}>#{channel.name}</b> <span style={{color: '#91909A', fontWeight: 500}}>{channel.id}</span></div>}
            filterPredicate={(search, channel) => channel.name.toLowerCase().includes(search.toLowerCase()) || (channel.id + '').toLowerCase().includes(search.toLowerCase())}
            selectedItem={selectedChannel}
            onChange={setSelectedChannel}
            icon={null}
            emptyLabel="Select or type channel name"
        />}

        <p style={{ fontSize: 12, color: '#777680', textAlign: 'right' }}>
            Can’t see the right channel? <b onClick={() => {
                setAllChannels(null)
                setSelectedChannel(isFaq ? [] : null)
                setIsSuccess(false)
                loadChannels()
            }} style={{ color: '#5865F2', cursor: 'pointer', borderBottom: '1px solid #5865F2', paddingBottom: 0 }}>Refresh channel list</b>
        </p>

        {isLoading && <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '5px'}}>
            <BallTriangle
            height="50"
            width="50"
            color='rgb(88, 101, 242)'
            ariaLabel='loading'
            />
        </div>}

        {isSuccess && <div>
            <div style={{ display: 'flex', fontSize: 16, fontWeight: 700, marginTop: 20 }}>
                <div>
                    <img width="18" src={IconCheckmarkSuccess}/>
                </div>
                <span style={{ marginLeft: 10}}>Valid channel(s) to support onboarding</span>
            </div>
        </div>}

        {!isSuccess && error && <div style={{ marginTop: 10 }}>
            <b style={{ color: '#BA1A1A' }}>{error}</b>
        </div>}

        <hr style={{background: '#E3E1EC', border: 'none', height: 1, margin: '20px 0'}}/>

        <div style={{fontWeight: 500, fontSize: 16, textAlign: 'left'}}>
            FAQ
        </div>

        <div>
            {FAQ_LINKS.map(({ title, url }) => {
                return <a href={url} target="_blank" style={{ margin: 0, display: 'inline' }}>
                    <div style={{ display: 'flex', fontSize: 16, fontWeight: 700, marginTop: 15 }}>
                        <div>
                            <img width="24" src={IconVideo}/>
                        </div>
                        <span style={{ marginLeft: 5}}>{title}</span>
                    </div>
                </a>
            })}
        </div>
    </div>
}

export default FtuxStep5SetupOnboarding