import { useEffect, useState, useRef } from 'react'
import IconChecked from '../icons/radio-checked.png';
import IconEmpty from '../icons/radio-empty.png';
import CollapseSvg from '../icons/welcome-party/CollapseSvg'

export const SearchableDropdown = ({ items, renderItem, filterPredicate, icon, isLoading, selectedItem, onChange, emptyLabel, isAdminPortalCommunitySwitch = false }) => {

    const [searchKey, setSearchKey] = useState("")
    const [isOpen, setIsOpen] = useState(false)

    const [searchResults, setSearchResults] = useState(null)
    const searchFieldRef = useRef()

    useEffect(() => {
        if(isOpen) {
            searchFieldRef.current.focus();
        }
    }, [isOpen])

    useEffect(() => {
        if(!isOpen) {
            return;
        }
        const res = []

        for(const item of items) {
            if(filterPredicate(searchKey, item)) {
                res.push(item)
                if(res.length === 10) {
                    break
                }
            }
        }

        setSearchResults(res)
    }, [searchKey, isOpen])

    return <div style={{
        background: 'rgb(245, 246, 253)',
        border: isAdminPortalCommunitySwitch ? '2px solid #BEC2FF' : '1px solid #E0E0FF',
        borderRadius: isAdminPortalCommunitySwitch ? 4 : 0,
        display: isAdminPortalCommunitySwitch ? 'inline-flex' : 'flex',
        alignItems: 'center',
        padding: isAdminPortalCommunitySwitch ? '7px 10px' : 12,
        position: 'relative'
    }}>
        {icon && <div style={{ marginLeft: 5, marginRight: 10, opacity: selectedItem ? 1 : 0.5 }}>
            <img src={icon} width="20"/>
        </div>}
        {isLoading ? <div style={{ flex: 1, textAlign: 'left', fontSize: 14 }}>Loading...</div> : <div style={{ flex: 1, cursor: 'pointer' }} onClick={() => { setIsOpen(true); setSearchKey('')}}>
            <div style={{width: '100%', textAlign: 'left',  opacity: selectedItem ? 1 : 0.5, fontSize: 14, fontWeight: 500}}>
                {selectedItem ? renderItem(selectedItem) : emptyLabel} {isAdminPortalCommunitySwitch && <span style={{ position: 'relative', top: 3}}><CollapseSvg width="16" height="16"/></span>}
            </div>
            {isOpen && <>
                <div style={{ position: 'absolute', top: -1, left: -1, width: 'calc(100% + 2px)', zIndex: 2, background: 'rgb(245, 246, 253)' }}>
                    <input ref={searchFieldRef} type="text" value={searchKey} onChange={e => setSearchKey(e.target.value)} style={{
                        background: '#F1EFFF',
                        width: '100%'
                    }} onClick={() => setIsOpen(true)} placeholder="Search..."/>
                    <div style={{ border: '1px solid #E0E0FF', borderTop: 'none' }}>
                        {searchResults?.map((item, i) => <div className="searchable-dropdown-item" onClick={e => {
                            onChange(item);
                            e.stopPropagation();
                            setIsOpen(false)
                        }} style={{ cursor: 'pointer', textAlign: 'left', padding: '12px 10px', borderTop: i > 0 ? '2px solid white' : 'none' }}>
                            {renderItem(item)}
                        </div>)}
                    </div>
                </div>
                <div onClick={e => {e.stopPropagation(); setIsOpen(false)}} style={{ position: 'fixed', background: 'rgba(0,0,0,.01)', top: 0, left: 0, right: 0, bottom: 0, zIndex: 1 }}>

                </div>
            </>}
        </div>}
        
    </div>
}

export default SearchableDropdown