import { useEffect, useState } from "react";
import axios from '../../../utils/axios';
import SearchableDropdown from '../../SearchableDropdown'

import IconCheckmarkSuccess from "../../../icons/checkmark-success.png"
import IconVideo from "../../../icons/video.png"


export const FtuxStepSetupSlug = ({ onboardingObject, setOnboardingObject, currentStep }) => {

    const communityName = onboardingObject.guildName

    const slug = onboardingObject.slug

    const [isLoading, setIsLoading] = useState(false)
    const [isSlugTaken, setIsSlugTaken] = useState(false)

    const isSlugValid = slug && slug.match(/^[0-9a-zA-Z\-_]+$/)

    const showSlugInvalid = slug && slug.length > 0 && !isSlugValid
    

    useEffect(() => {
        if(!communityName) {
            axios.get("https://discord.com/api/v8/users/@me/guilds").then(response => {
                for(const guild of response.data) {
                    if(guild.id == onboardingObject.guild_id) {
                        setOnboardingObject({
                            ...onboardingObject,
                            guildName: guild.name
                        })
                        break;
                    }
                }
            })
        }
    }, [])

    useEffect(() => {
        setOnboardingObject({
            ...onboardingObject,
            isSlugValid: isSlugValid
        })
    }, [slug])

    return <div style={{width: '100%', textAlign: 'left'}}>
        {/* <p style={{ color: '#777680', fontSize: 16, lineHeight: '24px', fontWeight: 500, textAlign: 'left', lineHeight: '24px' }}>
            lorem ipsum
        </p> */}

        <p style={{ color: '#212E47', fontSize: 12, fontWeight: 500, textAlign: 'left', lineHeight: '24px', marginBottom: 10}}>Community: <b>{communityName ? communityName : 'Loading...'}</b></p>

        <h3 style={{fontSize: 20, fontWeight: 500, textAlign: 'left', marginTop: 0}}>{currentStep.stepTitle}</h3>

        <p style={{ color: '#777680', fontSize: 14, lineHeight: '20px', fontWeight: 400, textAlign: 'left', fontFamily: 'Roboto', letterSpacing: '0.25px' }}>
            Select the community URL for your community admin dashboard.
        </p>


        app.landing.party/ <input type="text" placeholder='Type URL here...' value={slug} onChange={e => {
                                        setOnboardingObject({
                                            ...onboardingObject,
                                            slug: e.target.value.replace(/[^0-9a-zA-Z\-_]/gi, '')
                                        })
                                    }} style={{flex: 1, fontWeight: 500, width: 300, height: 25, padding: 5}} maxLength="100"/>

        {/* <hr style={{background: '#E3E1EC', border: 'none', height: 1, margin: '20px 0'}}/>

        <div style={{fontWeight: 500, fontSize: 16, textAlign: 'left'}}>
            FAQ
        </div>

        <div>
            <div style={{ display: 'flex', fontSize: 16, fontWeight: 700, marginTop: 15 }}>
                <img width="24" src={IconVideo}/>
                <span style={{ marginLeft: 5}}>Why do I need to create slug?</span>
            </div>
        </div> */}
    </div>
}

export default FtuxStepSetupSlug