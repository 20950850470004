import { useEffect, useState } from "react";
import axios from '../../../utils/axios';

import CheckBox from "../../CheckBox";
import RadioBox from "../../RadioBox";

import CaptchaExampleMath from "../../../icons/captcha-example-math.png"
import CaptchaExampleText from "../../../icons/captcha-example-text.png"

export const FtuxStep1SetupCaptcha = ({ onboardingObject, setOnboardingObject, currentStep }) => {
    const [isCaptchaEnabled, setIsCaptchaEnabled] = useState(onboardingObject.captcha !== 'NO')
    const [isMathCaptcha, setIsMathCaptcha] = useState(onboardingObject.captcha !== 'TE')

    console.log('obj', onboardingObject)

    useEffect(() => {
        const symbol = isCaptchaEnabled ? (isMathCaptcha ? 'MA' : 'TE') : 'NO'

        setOnboardingObject({
            ...onboardingObject,
            captcha: symbol
        })
    }, [isCaptchaEnabled, isMathCaptcha])

    return <div style={{width: '100%'}}>
        <h3 style={{fontSize: 20, fontWeight: 500, textAlign: 'left'}}>{currentStep.stepTitle}</h3>
        <p style={{ color: '#777680', fontSize: 14, lineHeight: '20px', fontWeight: 400, textAlign: 'left', fontFamily: 'Roboto', letterSpacing: '0.25px' }}>
            Captcha will be added at the beginning of the flow. If you wish to enable it, check the box below and choose your style. Users will be allowed to enter captcha up to 5 times before being kicked.
        </p>
        <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 30,
            marginBottom: 20,
        }}>
            <div style={{fontWeight: 500, fontSize: 16}}>
                Enable captcha?
            </div>
            <CheckBox
                defaultChecked={isCaptchaEnabled}
                onChange={setIsCaptchaEnabled}
                isBlue={true}
            />
        </div>
        <hr style={{background: '#E3E1EC', border: 'none', height: 1}}/>
        {isCaptchaEnabled && <div>
            <div style={{fontWeight: 500, fontSize: 16, textAlign: 'left', marginTop: 20, marginBottom: 0}}>
                Captcha style
            </div>
            <div style={{textAlign: 'left', marginTop: 3}}>
                <div style={{display: 'inline-block', marginRight: 50, marginTop: 5}} className="first-captcha-block">
                    <div style={{ display: 'inline-block', width: 75}}>  
                        <RadioBox
                            checked={isMathCaptcha}
                            onCheck={() => setIsMathCaptcha(true)}
                            label="Math"
                        />
                    </div>
                    {/* <br/> */}
                    <img src={CaptchaExampleMath} width="150" style={{ marginLeft: 10, position: 'relative', top: 3}}/>
                </div>
                <div style={{display: 'inline-block', marginTop: 5}}>
                    <div style={{ display: 'inline-block', width: 75}}>  
                        <RadioBox
                            checked={!isMathCaptcha}
                            onCheck={() => setIsMathCaptcha(false)}
                            label="Text"
                        />
                    </div>
                    {/* <br/> */}
                    <img src={CaptchaExampleText} width="150" style={{ marginLeft: 10, top: 3, position: 'relative' }}/>
                </div>
            </div>

            <div style={{fontWeight: 500, fontSize: 12, textAlign: 'left', marginTop: 30, letterSpacing: '0.4px'}}>
                Preview
            </div>

            <div style={{ background: '#2F3136', borderRadius: 5, borderLeft: '4px solid #5865F2', padding: '2px 15px', textAlign: 'left', marginTop: 10 }} className="discord-preview">
                <p style={{ fontWeight: 700, fontSize: 16, marginTop: 10 }}>
                    Verify yourself to gain access to the full server and channels
                </p>
                <p style={{ fontWeight: 500, fontSize: 13 }}>
                    Enter the result in the chat
                </p>
                <img src={isMathCaptcha ? CaptchaExampleMath : CaptchaExampleText} width="280" style={{ marginBottom: 10 }}/>
            </div>
        </div>}
    </div>
}

export default FtuxStep1SetupCaptcha