
export const DISCORD_LOGIN_URL = process.env.REACT_APP_DISCORD_LOGIN_URL


// Deprecated
export function Login() {
    return <div style={{marginTop: 30, textAlign: 'center'}}>
      <h3 style={{ textAlign: 'left' }}>Answered Questions</h3>
      <h3 style={{color: '#5865F2'}}>Please log in to view answered questions</h3>
      <button
        onClick={() => window.location = DISCORD_LOGIN_URL}
      >
        Log In
      </button>
    </div>
  }

export default Login;