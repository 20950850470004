import axios from 'axios';
import Swal from 'sweetalert2'


const instance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 10000,
    // headers: {'X-Custom-Header': 'foobar'}
  });

instance.interceptors.request.use(config => {
  console.log('config', config)
  if(config.url.includes("https://discord.com")) {
    if(config.url.includes("/channels") || config.url.includes("/roles")) {
      const token = "OTQ2NzYyMDYwNDE3NjMwMjI4.G08xai.w1oOh3vQS2Fno-6jDiHT7K2mHs9-opm2e7AEFk"
      config.headers.Authorization = 'Bot ' + token
    }
    else if(localStorage.getItem('discordAccessToken')) {
      config.headers.Authorization = 'Bearer ' + localStorage.getItem('discordAccessToken');
    }
  } else {
    if(localStorage.getItem('accessToken')) {
      config.headers.Authorization = 'Bearer ' + localStorage.getItem('accessToken');
    }
  }
  return config;
});

instance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  console.log("error", error.response.status)
  console.log(error)
  if (401 === error.response.status) {
    const isWelcomePartyLogin = localStorage.getItem('isWelcomePartyLogin') === '1'
    if(isWelcomePartyLogin) {
      window.location = "/welcome-party/sign-in"
    } else {
      window.location = "/"
    }
  } 
  else if(429 === error.response.status) {
    console.log('here2')
    Swal.fire(
      'Too many requests',
      'Please try again in a few minutes',
      'error'
    )
  }
  else {
    return Promise.reject(error);
  }
});



export default instance