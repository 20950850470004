import { useState } from 'react'
import IconChecked from '../icons/radio-checked.png';
import IconEmpty from '../icons/radio-empty.png';

export const RadioBox = ({ checked, label, onCheck }) => {
    return <div style={{ display: 'inline-flex', cursor: 'pointer' }} onClick={() => {
        if(!checked) {
            onCheck()
        }
    }}>
        <img src={checked ? IconChecked : IconEmpty} alt="checkbox" width="24"/>
        <span style={{ color: '#001A40', marginLeft: 8, marginTop: 4, fontWeight: 500, fontSize: 16 }}>{label}</span>
    </div>
}

export default RadioBox