import { useSearchParams } from 'react-router-dom'
import { useEffect } from 'react';
import axios from '../utils/axios';
import { BallTriangle } from  'react-loader-spinner'

function DiscordLoginCallback({ refreshUser }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const code = searchParams.get("code")

    const lastGuildSlug = localStorage.getItem('lastGuildSlug')
  
    useEffect(() => {
      axios.post('/login_with_discord_code', {
        "code": code,
        lastGuildSlug
      }).then(response => {
        console.log('auth response', response.data)
        const { success, refresh, access, discord_access_token } = response.data
        if(!success) {
            console.log('error', response.data)
            window.location = "/"
        }
        localStorage.setItem('discordAccessToken', discord_access_token)
        localStorage.setItem('accessToken', access)
        localStorage.setItem('refreshToken', refresh)
        const isWelcomePartyFaqLogin = localStorage.getItem('isWelcomePartyFaqLogin') === '1'
        const isWelcomePartyLogin = localStorage.getItem('isWelcomePartyLogin') === '1'
        if(isWelcomePartyFaqLogin) {
          window.location = "/faq-setup/select-community"
        } else if(isWelcomePartyLogin) {
          if(response.data.show_onboarding_flow_ftux) {
            window.location = "/welcome-party/first-time-setup"
          } else {
            window.location = "/welcome-party/dashboard"
          }
         
        } else {
          window.location = "/" + localStorage.getItem('lastGuildSlug')
        }
      }).catch(e => {
        console.log('error', e)
        window.location = "/"
      })
    }, [])
  
    return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 200, flexDirection: 'column'}}>
        <BallTriangle
        height="100"
        width="100"
        color='rgb(88, 101, 242)'
        ariaLabel='loading'
        />
      <div style={{ marginTop: 20}}>
        {'Loading...'}
      </div>
    </div>
}

export default DiscordLoginCallback;