import { useEffect, useState } from "react";


export const NeedHelpBottomLeft = ({ props }) => {

    return <div className="need-help-container" style={{ position: 'fixed', right: 0, bottom: 0, background: '#5865F2', padding: '5px 10px', borderRadius: '8px 0 0 0' }}>
        <a style={{ textDecoration: 'none', fontSize: 12, margin: 0 }} className="white" href="https://discord.gg/Qqdt2C4DCc" target="_blank">Need Help?</a>
    </div>
}

export default NeedHelpBottomLeft