import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"
import axios from '../../utils/axios';
import { generateAvatarImage } from '../../utils/discord'
// import BackgroundNetworkLogo from '../../icons/background-network-logo.png'
import LogoLightTheme from '../../icons/welcome-party/logo-light-theme.png';
import { logOut } from '../../utils/auth'

import CollapseSvg from "../../icons/welcome-party/CollapseSvg";

export function WelcomePartyTopBar({ userInfo, isDashboard }) {

    const [isCollapseOpen, setIsCollapseOpen] = useState(false)

    const navigate = useNavigate()

    return <div className="top-bar" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', padding: '5px 15px', paddingBottom: '20px', background: 'white', borderBottom: '1px solid #E3E1EC'}}>
            <div className="community-name-container" style={{display: 'flex', alignItems: 'flex-end', flex: 1}}>
                <div>
                  <div style={{display: 'inline-block'}}>
                    <img src={LogoLightTheme} alt="logo" height="40" style={{ position: 'relative', marginBottom: -12 }}/>
                  </div>
                </div>
            </div>
            <div className={"auth-info-container " + (isDashboard && "dashboard")} style={{display: 'flex', alignItems: 'center', justifySelf: 'flex-end', position: 'relative', top: 8, marginRight: 15 }}>
                  {isDashboard && <button style={{ marginRight: 15 }} onClick={() => navigate('/welcome-party/new-flow')}>
                    + Add survey to another community
                  </button>}
                  {userInfo ? 
                      <div className="avatar-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <div className="avatar" style={{display: 'inline-block', width:36, height: 36}}>
                              {generateAvatarImage(userInfo)}
                          </div>
                          <span style={{ marginLeft: 10, marginRight: 15 }}>{userInfo.discord_username}</span>
                          <div style={{ transform: isCollapseOpen ? 'rotate(180deg)' : 'none', position: 'relative' }}
                                onClick={() => setIsCollapseOpen(!isCollapseOpen)}
                                className={"collapse-icon " + (isCollapseOpen ? '' : 'closed')}
                            >
                                <CollapseSvg/>
                            </div>
                            {isCollapseOpen && <>
                                <div className="dropdown-bg" onClick={() => setIsCollapseOpen(false)} style={{ zIndex: 9, position: 'fixed', left: 0, right: 0, top: 0, bottom: 0, background: 'rgba(0,0,0,.1)', cursor: 'pointer' }}/>
                                <div className="dropdown-body" style={{ zIndex: 999, position: 'absolute', top: 45, right: 0, width: 80, background: 'white', padding: 7, border: '1px solid #E3E1EC' }}>
                                    <a onClick={() => logOut({isWelcomeParty: true})} style={{color: '#5865F2', margin: '0 10px', fontWeight: 300, fontSize: 14, borderBottom: 'none'}}>Sign Out</a>
                                </div>
                            </>}
                      </div> : 
                      <>
                          <button
                              onClick={() => {
                                localStorage.setItem('isWelcomePartyLogin', '1')
                                window.location = '/welcome-party/sign-in'
                              }}
                          >
                              Log In
                          </button>
                      </>
                  }
            </div>
      </div>
}

export default WelcomePartyTopBar