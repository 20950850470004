import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom"
import axios from '../../../utils/axios';

import WelcomePartyFtuxSteps from "../WelcomePartyFtuxSteps";


export function WelcomePartyDashboardFlowEdit({ userInfo }) {

    const params = useParams();

    const onboardingObjectId = params.id


    return <WelcomePartyFtuxSteps userInfo={userInfo} editedOnboardingObjectId={onboardingObjectId} />
}

export default WelcomePartyDashboardFlowEdit