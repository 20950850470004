import { useEffect, useRef, useState } from "react";
import './App.css';
import { BrowserRouter, Route, Routes, useSearchParams, useNavigate } from "react-router-dom"
import axios from './utils/axios'
import ReactInterval from 'react-interval';
import DiscordLoginCallback from './components/DiscordLoginCallback';
import Login from './components/Login';
import WelcomePartyLogin from './components/welcome-party/WelcomePartyLogin';
import WelcomePartyFtuxSteps from './components/welcome-party/WelcomePartyFtuxSteps';
import WelcomePartyDashboard from './components/welcome-party/WelcomePartyDashboard';
import WelcomePartyDashboardFlowEdit from "./components/welcome-party/dashboardTabs/WelcomePartyDashboardFlowEdit.js";
import QaList from "./components/QaList";
import { refreshToken } from './utils/auth'
import { BallTriangle } from  'react-loader-spinner'


const Redirect = ({ url }) => {
  window.location = url

  return <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '100px'}}>
    <BallTriangle
      height="50"
      width="50"
      color='rgb(88, 101, 242)'
      ariaLabel='loading'
    />
  </div>
}

function App() {
  const [userInfo, setUserInfo] = useState(undefined)

  const lastGuildSlug = localStorage.getItem('lastGuildSlug')
  const isWelcomePartyLogin = localStorage.getItem('isWelcomePartyLogin') === '1'
  const isWelcomePartyFaqLogin = localStorage.getItem('isWelcomePartyFaqLogin') === '1'

  const isAuthenticated = !!userInfo

  const isDiscordCallbackRoute = window.location.pathname === "/discord_login_callback"
  const isWelcomePartyRoute = window.location.pathname.includes("/welcome-party")

  if(window.location.hostname === 'app.background.network') {
    window.location = 'https://app.landing.party' + window.location.pathname
  }

  const refreshUser = () => {
    if(localStorage.refreshToken) {
      axios.get('/get_user').then(response => {
        setUserInfo(response.data)
      }).catch(() => {
        setUserInfo(null)
        localStorage.removeItem('refreshToken')
        localStorage.removeItem('accessToken')
      })
    } else {
      setUserInfo(null)
    }
  }

  useEffect(() => {
    refreshUser()
  }, [])

  if(userInfo === undefined && !isDiscordCallbackRoute) {
    return <div style={{display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 200, flexDirection: 'column'}}>
              <BallTriangle
              height="100"
              width="100"
              color='rgb(88, 101, 242)'
              ariaLabel='loading'
              />
            <div style={{ marginTop: 50}}>
              Loading user info...
            </div>
      </div>
  }

  const guestRoutes = <>
    <Route exact path="/:guild_slug" element={<QaList userInfo={null}/>}/>
    {lastGuildSlug ?
      <Route exact path="*" element={<Redirect url={`/${lastGuildSlug}`}/>}/> :
      <Route exact path="*" element={<Redirect url="/faq-setup/sign-in"/>}/>
    }
  </>

  const authRoutes = <>
      <Route exact path="/:guild_slug/create" element={<QaList userInfo={userInfo} createFormShown={true}/>}/>
      <Route exact path="/:guild_slug" element={<QaList userInfo={userInfo}/>}/>
      {lastGuildSlug ?
        <Route exact path="*" element={<Redirect url={`/${lastGuildSlug}`}/>}/> :
        <Route exact path="*" element={<Redirect url="/faq-setup/sign-in"/>}/>
      }
  </>

  // const welcomePartyRoutes = <>
  //   <Route exact path="/welcome-party/sign-in" element={<WelcomePartyLogin/>}/>
    
  //   {isAuthenticated && <>
  //     <Route path="/welcome-party/first-time-setup/*" element={<WelcomePartyFtuxSteps userInfo={userInfo} isFtux={true}/>}/>
  //     <Route path="/welcome-party/new-flow" element={<WelcomePartyFtuxSteps userInfo={userInfo}/>}/>
  //     <Route path="/welcome-party/new-flow/*" element={<WelcomePartyFtuxSteps userInfo={userInfo}/>}/>
  //     <Route path="/welcome-party/dashboard" element={<Redirect url="/welcome-party/dashboard/automation"/>}/>
  //     <Route path="/welcome-party/dashboard/automation/:id/edit" element={<WelcomePartyDashboardFlowEdit userInfo={userInfo}/>}/>
  //     <Route path="/welcome-party/dashboard/automation/:id/edit/*" element={<WelcomePartyDashboardFlowEdit userInfo={userInfo}/>}/>
  //     <Route path="/welcome-party/dashboard/:tab" element={<WelcomePartyDashboard userInfo={userInfo}/>}/>
  //   </>}

  //   {!isAuthenticated && <>
  //     <Route path="*" element={<Redirect url="/welcome-party/sign-in"/>}/>
  //   </>}
  // </>

  const faqCommunitySetupRoutes = <>
    <Route exact path="/faq-setup/sign-in" element={<WelcomePartyLogin isFaq={true}/>}/>

    {isAuthenticated && <>
      <Route path="/faq-setup/*" element={<WelcomePartyFtuxSteps userInfo={userInfo} isFtux={true} isFaq={true}/>}/>
    </>}

    {/* {!isAuthenticated && <>
      <Route path="*" element={<Redirect url="/faq-setup/sign-in"/>}/>
    </>} */}
  </>

  return <div className="root">
      <div className="root-container">
        <ReactInterval timeout={3 * 60 * 1000} enabled={!!userInfo} callback={refreshToken} />
        <Routes>
            <Route exact path="/discord_login_callback" element={<DiscordLoginCallback setUserInfo={setUserInfo}/>}/>
            {/* { isWelcomePartyRoute && <>
              {welcomePartyRoutes}
            </>} */}
            {faqCommunitySetupRoutes}
            {
              !isDiscordCallbackRoute && !isWelcomePartyRoute && <>
                {isAuthenticated && authRoutes}
                {!isAuthenticated && guestRoutes}
              </>
            }
        </Routes>
      </div>
  </div>
}

function RouterApp() {
  return <BrowserRouter>
    <App/>
  </BrowserRouter>
}

export default RouterApp;
