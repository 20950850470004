import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from 'uuid';

import DragHandleIcon from "../../../icons/drag-handle.png"
import DeleteIconBlue from "../../../icons/delete-icon-blue.png"
import CheckBox from "../../CheckBox";

// fake data generator
const getItems = count =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: uuidv4(),
    content: '',
    optional: false
  }));

const transformOnboardingObjIntoItems = (obj) => {
    const items = []

    for(var i = 1; i <= 5; i++) {
        if(obj[`question_${i}`] === null) {
            break;
        }
        items.push({
            id: uuidv4(),
            content: obj[`question_${i}`],
            optional: !obj[`question_${i}_required`]
        })
    }

    if(items.length === 0) {
        items.push({
            id: uuidv4(),
            content: '',
            optional: false
        })
    }

    return items
}

const transformItemsIntoOnboardingObj = (items, obj) => {
    const newObj = {...obj}

    var i;

    for(i = 0; i < items.length; i++) {
        newObj[`question_${i+1}`] = items[i].content
        newObj[`question_${i+1}_required`] = !items[i].optional
    }

    for(i = items.length; i < 5; i++) {
        newObj[`question_${i+1}`] = null
        newObj[`question_${i+1}_required`] = false
    }

    return newObj
}

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: 0,//grid * 2,
  marginTop: 20,

  // change background colour if dragging
  background: isDragging ? "rgba(0,0,0,.1)" : "transparent",
  textAlign: 'left',

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: 'transparent',//isDraggingOver ? "lightblue" : "lightgrey",
//   padding: grid,
//   width: 250
});

class FtuxStep2BuildSurvey extends Component {
  constructor(props) {
    super(props);
    const { onboardingObject, setOnboardingObject} = props;
    this.state = {
      items: transformOnboardingObjIntoItems(onboardingObject)
    };

    this.onDragEnd = this.onDragEnd.bind(this);
  }

  updateItems(items) {
    const { onboardingObject, setOnboardingObject} = this.props;

    setOnboardingObject( transformItemsIntoOnboardingObj(items, onboardingObject) )

    this.setState({
      items
    });
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.updateItems(items)
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    return ( <>
      <h3 style={{fontSize: 20, fontWeight: 500, textAlign: 'left'}}>{this.props.currentStep.stepTitle}</h3>
        <p style={{ color: '#777680', fontSize: 14, lineHeight: '20px', fontWeight: 400, textAlign: 'left', fontFamily: 'Roboto', letterSpacing: '0.25px', marginBottom: 25 }}>
        Add up to 5 open questions to your survey. The survey will be presented to users within a modal. 
        </p>
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {this.state.items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                      )}
                    >
                        <img src={DragHandleIcon} {...provided.dragHandleProps} style={{ marginLeft: 1, position: 'relative', top: 5 }} width="20"/>
                        <span style={{fontSize: 12, fontWeight: 600, marginLeft: 10}}>QUESTION {index+1}/{this.state.items.length}</span>
                        <br/>
                        
                        <div style={{display: 'flex', width: '100%', marginTop: 10} }>
                            <input type="text" placeholder='Type your question here...' value={item.content} onChange={e => {
                                        const copy = [...this.state.items]
                                        copy[index].content = e.target.value
                                        this.updateItems(copy)
                                    }} style={{flex: 1, fontWeight: 500}} maxLength="100"/>
                            <div style={{ padding: 12, marginRight: 7 }}>
                                <CheckBox
                                    defaultChecked={item.optional}
                                    onChange={newval => {
                                        const copy = [...this.state.items]
                                        copy[index].optional = newval
                                        this.updateItems(copy)
                                    }}
                                    isBlue={true}
                                    label="Optional"
                                    checkboxSize={20}
                                />
                            </div>
                            {this.state.items.length > 1 && <img src={DeleteIconBlue} width="24" height="24" style={{marginTop: 11, cursor: 'pointer'}} 
                                onClick={() => {
                                    const copy = [...this.state.items]
                                    copy.splice(index, 1)
                                    this.updateItems(copy)
                                }}
                            />}
                        </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {this.state.items.length < 5 && <button style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop:20, height: 32, fontSize: 14}}
            onClick={() => {
                const copy = this.state.items.concat({
                    id: uuidv4(),
                    optional: false,
                    content: ''
                })
                this.updateItems(copy)
            }}    
        >
        <span style={{fontSize: 24, color: 'white', marginRight: 10, marginTop: -4}}>+</span>
        Add question to form
      </button>}
    </>);
  }
}

export default FtuxStep2BuildSurvey