// import BnLogoWithText from '../../icons/welcome-party/bn-logo-w-text.png';
// import SignInFrameRight from '../../icons/welcome-party/sign-in-frame-right.jpg';
import DiscordLogo from '../../icons/welcome-party/discord-logo.png';

import LoginBg from '../../icons/welcome-party/login-bg.png';
import LoginBgInner from '../../icons/welcome-party/login-bg-inner.svg';
import LogoDarkTheme from '../../icons/welcome-party/logo-dark-theme.png';

import gtag, { install } from 'ga-gtag';

export const DISCORD_LOGIN_URL = process.env.REACT_APP_DISCORD_LOGIN_URL


export function WelcomePartyLogin({ isFaq }) {
    return <div style={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        backgroundImage: `url(${LoginBg})`,
        backgroundSize: 'cover'
    }} className="welcome-party-root login">
        {install('G-LP0XM7N21X')}
        <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <div>
                <img src={LogoDarkTheme} width="330" style={{ pointerEvents: 'none', marginBottom: 30 }}/>
                <div style={{
                    // display: 'inline-block',
                    background: `url(${LoginBgInner})`,
                    backgroundSize: 'cover',
                    backdropFilter: 'blur(40px)',
                    width: '379px',
                    height: '220px',
                    margin: 'auto',
                    // borderRadius: 20,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center'
                }}>
                    <p style={{fontWeight: 500, fontSize: 20, color: '#F1EFFF', marginBottom: 40}}>Create an account or login</p>
                    <div>
                        <button style={{ background: '#5865F2', padding: '12px 25px' }} onClick={() => {
                            gtag('event', 'login', { 'method': 'Discord' });
                            if(isFaq) {
                                localStorage.setItem('isWelcomePartyLogin', '0')
                                localStorage.setItem('isWelcomePartyFaqLogin', '1')
                            } else {
                                localStorage.setItem('isWelcomePartyLogin', '1')
                                localStorage.setItem('isWelcomePartyFaqLogin', '0')
                            }
                            window.location = DISCORD_LOGIN_URL
                        }}>
                            <img src={DiscordLogo} width="20" style={{
                                marginRight: 8,
                                marginTop: 2
                            }}/>
                            <b style={{fontSize: 18, fontWeight: '700', color: 'white'}}>Access with Discord</b>
                        </button>
                    </div>
                </div>
                {/* <div style={{ marginTop: 25 }}>
                    <a href='#'>Terms and conditions</a>
                    <a href='#'>Privacy policy</a>
                </div> */}
                <div style={{ marginTop: 25, color: '#F1EFFF', fontSize: 16 }} >
                    Want to chat with our team?<a style={{ fontSize: 16, marginLeft: 5, marginRight: 0 }} href="https://xfzeu952cha.typeform.com/to/gmxv18UP" target="_blank">Contact us</a>
                </div>
            </div>
        </div>
    </div>
  }

export default WelcomePartyLogin;