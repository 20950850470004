import { useEffect, useState } from "react";
import axios from '../../../utils/axios';

import SearchableDropdown from '../../SearchableDropdown'
import IconCommunity from "../../../icons/community.png"
import IconVideo from "../../../icons/video.png"

import { BallTriangle } from "react-loader-spinner";

const FAQ_LINKS = [
    {
        title: 'How do I enable Developer mode?',
        url: 'https://www.landing.party/scout-install#enable-developer-mode'
    },
    {
        title: 'How can I find my server ID?',
        url: 'https://www.landing.party/scout-install#find-server-id'
    },
    {
        title: 'I cannot see the right server in the dropdown',
        url: 'https://www.landing.party/scout-install#cannot-see-right-server'
    }
]


export const FtuxStep3CreateAccount = ({ onboardingObject, setOnboardingObject, isFtux, currentStep, isFaq }) => {

    const [manageableGuilds, setManageableGuilds] = useState(null)
    const [selectedGuild, setSelectedGuild] = useState(null)

    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(null)
    const [error, setError] = useState(null)
    const [existingGuildSlug, setExistingGuildSlug] = useState(null)

    function checkSuccess() {
        if(!selectedGuild) {
            return
        }
        setIsLoading(true)
        setError(null)
        axios.get(isFaq ? `/does_guild_have_faq_bot/${selectedGuild.id}` : `/onboarding/does_guild_have_onboarding_flow/${selectedGuild.id}`).then(response => {
            if(isFaq) {
                if(response.data.faq_bot_exists) {
                    setIsSuccess(false)
                    setError("Faq bot is already set up in this guild")
                    setExistingGuildSlug(response.data.slug)
                } else {
                    setIsSuccess(true)
                    setExistingGuildSlug(null)
                }
            } else {
                if(response.data.onboarding_exists) {
                    setIsSuccess(false)
                    setError("Onboarding flow already exists in this guild")
                } else {
                    setIsSuccess(true)
                }
            }
            setIsLoading(false)
        })
    }

    useEffect(() => {
        checkSuccess()
    }, [selectedGuild])

    useEffect(() => {
        setOnboardingObject({
            ...onboardingObject,
            validGuildSelected: isSuccess
        })
    }, [isSuccess])

    useEffect(() => {
        axios.get("https://discord.com/api/v8/users/@me/guilds").then(response => {
            const manGuilds = []
            for(const guild of response.data) {
                const MANAGE_GUILD = (1 << 5)
                const canManageGuild = Boolean(MANAGE_GUILD & (guild.permissions * 1)) 
                console.log(guild, canManageGuild)
                if(canManageGuild) {
                    manGuilds.push(guild)
                }
            }
            setManageableGuilds(manGuilds)
            if(onboardingObject.guild_id) {
                const matches = manGuilds.filter(x => x.id === onboardingObject.guild_id)

                if(matches.length > 0) {
                    setSelectedGuild(matches[0])
                }
            }
        })

    }, [])

    useEffect(() => {
        if(selectedGuild) {
            setOnboardingObject({
                ...onboardingObject,
                guild_id: selectedGuild.id,
                guildName: selectedGuild.name
            })
        }
    }, [selectedGuild])

    return <div style={{width: '100%'}}>
        <h3 style={{fontSize: 20, fontWeight: 500, textAlign: 'left'}}>{currentStep.stepTitle}</h3>

        <p style={{ color: '#777680', fontSize: 14, lineHeight: '20px', fontWeight: 400, textAlign: 'left', fontFamily: 'Roboto', letterSpacing: '0.25px' }}>
            Insert the Server Name or Server ID below. You need to be an Admin of the server in order to install Scout. If you don’t have admin permissions, you need to be granted Admin permissions in Discord in order to continue the set up process. If you need help, please contact support. 
        </p>

        <SearchableDropdown 
            isLoading={manageableGuilds === null}
            items={manageableGuilds}
            renderItem={guild => <div style={{fontSize: 14, color: '#001A40', letterSpacing: '0.15px'}}><b style={{fontWeight: 500}}>{guild.name}</b> <span style={{color: '#91909A', fontWeight: 500}}>{guild.id}</span></div>}
            filterPredicate={(search, guild) => guild.name.toLowerCase().includes(search.toLowerCase()) || (guild.id + '').toLowerCase().includes(search.toLowerCase())}
            selectedItem={selectedGuild}
            onChange={setSelectedGuild}
            icon={IconCommunity}
            emptyLabel="Select or type server name"
        />

        {isLoading && <div style={{display: 'inline-flex', height: '100%', justifyContent: 'center', alignItems: 'center', position: 'relative', top: 5, left: 10}}>
                <BallTriangle
                height="20"
                width="20"
                color='rgb(88, 101, 242)'
                ariaLabel='loading'
                />
        </div>}

        {!isSuccess && error && <div style={{ marginTop: 10, textAlign: 'left' }}>
            <b style={{ color: '#BA1A1A' }}>{error}</b>
            {existingGuildSlug && <a href={"/" + existingGuildSlug} style={{ fontSize: 16, marginLeft: 10 }}>Go to your dashboard</a>}
        </div>}

        <hr style={{background: '#E3E1EC', border: 'none', height: 1, margin: '20px 0'}}/>

        <div style={{fontWeight: 500, fontSize: 16, textAlign: 'left'}}>
            FAQ
        </div>

        <div>
            {FAQ_LINKS.map(({ title, url }) => {
                return <a href={url} target="_blank" style={{ margin: 0, display: 'inline' }}>
                    <div style={{ display: 'flex', fontSize: 16, fontWeight: 700, marginTop: 15 }}>
                        <div>
                            <img width="24" src={IconVideo}/>
                        </div>
                        <span style={{ marginLeft: 5}}>{title}</span>
                    </div>
                </a>
            })}
        </div>
    </div>
}

export default FtuxStep3CreateAccount