import { useEffect, useState } from "react";
import axios from '../../../utils/axios';
import SearchableDropdown from '../../SearchableDropdown'

import IconCheckmarkSuccess from "../../../icons/checkmark-success.png"
import IconVideo from "../../../icons/video.png"

const FAQ_LINKS = [
    {
        title: 'How do I create a role?',
        url: 'https://www.landing.party/scout-install#create-a-role'
    },
    {
        title: 'How can I find the role ID?',
        url: 'https://www.landing.party/scout-install#find-role-id'
    },
    {
        title: 'How can I check that the bot can speak in-channel?',
        url: 'https://www.landing.party/scout-install#check-bot-can-speak-in-channel'
    }
]


export const FtuxStep5SetupOnboarding = ({ onboardingObject, setOnboardingObject, currentStep, isFaq }) => {

    const communityName = onboardingObject.guildName

    const [allRoles, setAllRoles] = useState(null)
    const [selectedRole, setSelectedRole] = useState(null)

    function loadRoles() {
        axios.get(`/get_roles/${onboardingObject.guild_id}`).then(response => {
            console.log(response.data)
            // Filter @everyone role
            setAllRoles(response.data.filter(x => x.id != onboardingObject.guild_id))

            if(onboardingObject.role_id) {
                const matches = response.data.filter(x => x.id === onboardingObject.role_id)
    
                if(matches.length > 0) {
                    setSelectedRole(matches[0])
                }
            }

            if(onboardingObject.admin_role_id) {
                const matches = response.data.filter(x => x.id === onboardingObject.admin_role_id)
    
                if(matches.length > 0) {
                    setSelectedRole(matches[0])
                }
            }
        })
    }

    useEffect(() => {
        loadRoles()

        if(!communityName) {
            axios.get("https://discord.com/api/v8/users/@me/guilds").then(response => {
                for(const guild of response.data) {
                    if(guild.id == onboardingObject.guild_id) {
                        setOnboardingObject({
                            ...onboardingObject,
                            guildName: guild.name
                        })
                        break;
                    }
                }
            })
        }
    }, [])

    console.log('onboarding', onboardingObject)

    useEffect(() => {
        console.log('selected', selectedRole)
        if(selectedRole) {
            setOnboardingObject({
                ...onboardingObject,
                [isFaq ? 'admin_role_id' : 'role_id']: selectedRole.id
            })
        }
    }, [selectedRole])

    return <div style={{width: '100%', textAlign: 'left'}}>
        {/* <p style={{ color: '#777680', fontSize: 16, lineHeight: '24px', fontWeight: 500, textAlign: 'left', lineHeight: '24px' }}>
            lorem ipsum
        </p> */}

        <p style={{ color: '#212E47', fontSize: 12, fontWeight: 500, textAlign: 'left', lineHeight: '24px', marginBottom: 10}}>Community: <b>{communityName ? communityName : 'Loading...'}</b></p>

        <h3 style={{fontSize: 20, fontWeight: 500, textAlign: 'left', marginTop: 0}}>{currentStep.stepTitle}</h3>

        <p style={{ color: '#777680', fontSize: 14, lineHeight: '20px', fontWeight: 400, textAlign: 'left', fontFamily: 'Roboto', letterSpacing: '0.25px' }}>
            Discord users with this role will be able to manage FAQ questions in admin portal
        </p>


        <SearchableDropdown 
            isLoading={allRoles === null}
            items={allRoles}
            renderItem={role => <div style={{fontSize: 14, color: '#001A40', letterSpacing: '0.15px'}}><b style={{fontWeight: 500}}>{role.name}</b> <span style={{color: '#91909A', fontWeight: 500}}>{role.id}</span></div>}
            filterPredicate={(search, role) => role.name.toLowerCase().includes(search.toLowerCase()) || (role.id + '').toLowerCase().includes(search.toLowerCase())}
            selectedItem={selectedRole}
            onChange={setSelectedRole}
            icon={null}
            emptyLabel="Select or type role name"
        />
        <p style={{ fontSize: 12, color: '#777680', textAlign: 'right' }}>
            Can’t see the right channel? <b onClick={() => {
                setAllRoles(null)
                setSelectedRole(null)
                loadRoles()
            }} style={{ color: '#5865F2', cursor: 'pointer', borderBottom: '1px solid #5865F2', paddingBottom: 0 }}>Refresh role list</b>
        </p>

        <hr style={{background: '#E3E1EC', border: 'none', height: 1, margin: '20px 0'}}/>

        <div style={{fontWeight: 500, fontSize: 16, textAlign: 'left'}}>
            FAQ
        </div>

        <div>
            {FAQ_LINKS.map(({ title, url }) => {
                return <a href={url} target="_blank" style={{ margin: 0, display: 'inline' }}>
                    <div style={{ display: 'flex', fontSize: 16, fontWeight: 700, marginTop: 15 }}>
                        <div>
                            <img width="24" src={IconVideo}/>
                        </div>
                        <span style={{ marginLeft: 5}}>{title}</span>
                    </div>
                </a>
            })}
        </div>
    </div>
}

export default FtuxStep5SetupOnboarding