import { useState } from 'react'
import IconChecked from '../icons/cb-checked.png';
import IconEmpty from '../icons/cb-empty.png';
import IconCheckedBlue from '../icons/blue-checkbox-checked.png';
import IconEmptyBlue from '../icons/blue-checkbox-empty.png';

export const CheckBox = ({ defaultChecked, label, onChange, isBlue, checkboxSize }) => {
    const [checked, setChecked] = useState(defaultChecked)

    return <div style={{ display: 'inline-flex', cursor: 'pointer' }} onClick={() => {
        setChecked(!checked)
        onChange(!checked)
    }}>
        <img src={checked ? (isBlue ? IconCheckedBlue : IconChecked) : (isBlue ? IconEmptyBlue : IconEmpty)} alt="checkbox" width={checkboxSize || 24} height={isBlue ? checkboxSize || 24 : null}/>
        <span style={{ color: isBlue? '#001A40' : '#5865F2', marginLeft: 5, marginTop: isBlue? 2: 2, fontWeight: isBlue ? 500 : '' }}>{label}</span>
    </div>
}

export default CheckBox