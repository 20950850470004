import React from 'react';
import { toHTML } from 'discord-markdown';
import HtmlToReact from 'html-to-react'

const htmlToReactParser = new HtmlToReact.Parser();

export function formatText(text) {
    return htmlToReactParser.parse(toHTML(text, { escapeHTML: true }))
    // return <>
    //     {text.split("\n").filter(x => x).map((x, i) => <>
    //         {i > 0 && <br/>}
    //         <span>{x}</span>
    //     </>)}
    // </>
}