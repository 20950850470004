import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"
import axios from '../../utils/axios';
import FtuxStep1SetupCaptcha from './ftuxSteps/FtuxStep1SetupCaptcha'
import FtuxStep2BuildSurvey from './ftuxSteps/FtuxStep2BuildSurvey'
import FtuxStep3CreateAccount from './ftuxSteps/FtuxStep3CreateAccount'
import FtuxStep4LinkBot from './ftuxSteps/FtuxStep4LinkBot'
import FtuxStep5SetupChannel from './ftuxSteps/FtuxStep5SetupChannel'
import FtuxStep6SetupRole from './ftuxSteps/FtuxStep6SetupRole'
import FtuxStepSetupSlug from "./ftuxSteps/FtuxStepSetupSlug";

import NeedHelpBottomLeft from "./NeedHelpBottomLeft";

import WelcomePartyTopBar from "./WelcomePartyTopBar";

import FtuxSuccessPopupBg from '../../icons/welcome-party/ftux-success-popup-bg.png'
import FtuxSuccessPopupCheckmark from '../../icons/welcome-party/ftux-success-popup-checkmark.png'
import CollapseSvg from "../../icons/welcome-party/CollapseSvg";

import WelcomePartyBottomBar from "./WelcomePartyBottomBar";

import { BallTriangle } from  'react-loader-spinner'
import Swal from "sweetalert2";


const STEPS_CONFIG_CREATE = [
    {
        stepComponent: FtuxStep1SetupCaptcha,
        stepTitle: 'Set up captcha',
        stepLabel: 'Captcha',
        subpath: 'setup-captcha',
        isValid: onboardingObj => true
    },
    {
        stepComponent: FtuxStep2BuildSurvey,
        stepTitle: 'Build your onboarding survey',
        stepLabel: 'Build survey',
        subpath: 'build-survey',
        isValid: onboardingObj => onboardingObj && onboardingObj['question_1']
    },
    {
        stepComponent: FtuxStep3CreateAccount,
        stepTitle: 'It looks like you don’t have an account yet',
        stepLabel: 'Create account',
        subpath: 'create-account',
        isValid: onboardingObj => onboardingObj?.guild_id && onboardingObj?.validGuildSelected
    },
    {
        stepComponent: FtuxStep4LinkBot,
        stepTitle: 'Install Scout Bot',
        stepLabel: 'Link bot',
        subpath: 'link-bot',
        isValid: onboardingObj => onboardingObj?.botLinked
    },
    {
        stepComponent: FtuxStep5SetupChannel,
        stepTitle: 'Set up the onboarding flow in your community',
        stepLabel: 'Set up channel',
        subpath: 'setup-channel',
        isValid: onboardingObj => onboardingObj?.channel_id && onboardingObj?.channelLinked
    },
    {
        stepComponent: FtuxStep6SetupRole,
        stepTitle: 'Set up the onboarding flow in your community',
        stepLabel: 'Set up role',
        subpath: 'setup-role',
        isValid: onboardingObj => onboardingObj?.role_id
    },

]

const STEPS_CONFIG_CREATE_FAQ = [
    {
        stepComponent: FtuxStep3CreateAccount,
        stepTitle: 'Select the community to add Scout (our Discord bot) to',
        stepLabel: 'Select community',
        subpath: 'select-community',
        isValid: onboardingObj => onboardingObj?.guild_id && onboardingObj?.validGuildSelected
    },
    {
        stepComponent: FtuxStep4LinkBot,
        stepTitle: 'Install Scout Bot',
        stepLabel: 'Link bot',
        subpath: 'link-bot',
        isValid: onboardingObj => onboardingObj?.botLinked
    },
    {
        stepComponent: FtuxStepSetupSlug,
        stepTitle: 'Select your community URL',
        stepLabel: 'Set up URL',
        subpath: 'setup-slug',
        isValid:  onboardingObj => onboardingObj?.isSlugValid
    },
    {
        stepComponent: FtuxStep5SetupChannel,
        stepTitle: 'Select the channels you would like Scout to be active in',
        stepLabel: 'Set up channels',
        subpath: 'setup-channels',
        isValid: onboardingObj => onboardingObj?.channel_ids?.length > 0 && onboardingObj?.channelLinked
    },
    {
        stepComponent: FtuxStep6SetupRole,
        stepTitle: 'Select admin role',
        stepLabel: 'Set up admin role',
        subpath: 'setup-role',
        isValid: onboardingObj => onboardingObj?.admin_role_id
    }
]

const STEPS_CONFIG_EDIT = STEPS_CONFIG_CREATE.slice(0, 2).concat(STEPS_CONFIG_CREATE.slice(4))

export function WelcomePartyFtuxSteps({ userInfo, editedOnboardingObjectId = null, isFtux = false, isFaq }) {

    const STEPS_CONFIG = isFaq ? STEPS_CONFIG_CREATE_FAQ : editedOnboardingObjectId ? STEPS_CONFIG_EDIT : STEPS_CONFIG_CREATE

    const location = useLocation()
    const navigate = useNavigate()

    const [currentStepIndex, _setCurrentStepIndex] = useState(0)

    const [onboardingObject, setOnboardingObject] = useState(null)

    const [isLoading, setIsLoading] = useState(true)

    const [showSuccessPopup, setShowSuccessPopup] = useState(false)

    const [isHeaderCollapseOpen, setIsHeaderCollapseOpen] = useState(false)

    const [channelName, setChannelName] = useState('')

    function loadChannelName() {
        axios.get(`/get_channels/${onboardingObject.guild_id}`).then(response => {
            console.log(response.data)
            console.log('obj', onboardingObject)
            if(onboardingObject.channel_id) {
                const matches = response.data.filter(x => x.id == onboardingObject.channel_id)

                console.log('matches', matches)
    
                if(matches.length > 0) {
                    console.log(matches[0].name)
                    setChannelName(matches[0].name)
                }
            }
        })
    }

    const basePath = isFaq ? '/faq-setup' : '/welcome-party/first-time-setup'

    const setCurrentStepIndex = (i) => {
        const step = STEPS_CONFIG[i]
        const subpath = step.subpath

        if(!window.location.pathname.endsWith(subpath)) {
            var fullPath;
            if(editedOnboardingObjectId) {
                fullPath = `/welcome-party/dashboard/automation/${editedOnboardingObjectId}/edit/${subpath}`
            } else if(isFtux) {
                fullPath = `${basePath}/${subpath}`
            } else {
                fullPath = `/welcome-party/new-flow/${subpath}`
            }
            window.history.pushState({}, '', fullPath)
        }

        _setCurrentStepIndex(i)
    }

    // const [isNextStepEnabled, setIsNextStepEnabled] = useState(false)

    const currentStep = STEPS_CONFIG[currentStepIndex]

    const isNextStepEnabled = currentStep.isValid(onboardingObject)
    const isPreviousStepEnabled = currentStepIndex > 0

    const goToNextStep = () => {
        setCurrentStepIndex(currentStepIndex + 1)
    }

    const goToStep = step => {
        setCurrentStepIndex(step)
    }

    const goToPreviousStep = () => {
        setCurrentStepIndex(currentStepIndex - 1)
    }

    function syncPath() {
        for(var i = 0; i < STEPS_CONFIG.length; i++) {
            if(window.location.pathname.endsWith(STEPS_CONFIG[i].subpath)) {
                if(currentStepIndex !== i) {
                    setCurrentStepIndex(i)
                }
                break
            }
        }
    }

    useEffect(() => {
        syncPath()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    syncPath()

    function loadOnboardingObject() {
        setIsLoading(true)
        axios.get(editedOnboardingObjectId ? `/of_creation_objects/${editedOnboardingObjectId}` : isFaq ? '/get_new_faq_bot_obj' : isFtux ? "/get_ftux_onboarding_obj" : "/get_new_onboarding_obj").then(response => {
            if(!editedOnboardingObjectId && response.data.flow) {
                window.location = "/welcome-party/dashboard"
                return
            }
            if(editedOnboardingObjectId && !response.data.flow) {
                window.location = "/welcome-party/sign-in"
                return
            }
            setOnboardingObject(response.data)
            setIsLoading(false)
        }).catch(() => {
            console.log('here')
            window.location = "/welcome-party/sign-in"
        })
    }

    function syncOnboardingObject(callback) {
        setIsLoading(true)
        const data = {...onboardingObject}
        delete data.creator
        axios.patch(isFaq ? `/faq_creation_objects/${onboardingObject.id}` : `/of_creation_objects/${onboardingObject.id}`, data).then(response => {
            setOnboardingObject({
                ...response.data,
                guildName: onboardingObject.guildName
            })
            callback()
            setIsLoading(false)
        })
    }

    useEffect(() => {
        loadOnboardingObject()
    }, [])

    // useEffect(() => {
    //     axios.get("https://discord.com/api/v8/users/@me").then(response => {
    //         console.log('users/@me', response.data)
    //     })
    //     axios.get("https://discord.com/api/v8/users/@me/guilds").then(response => {
    //         console.log('users/@me/guilds', response.data)
    //         for(const guild of response.data) {
    //             const MANAGE_GUILD = (1 << 5)
    //             const canManageGuild = Boolean(MANAGE_GUILD & (guild.permissions * 1)) 
    //             console.log(guild.name, canManageGuild)
    //         }
    //     })
    //     axios.get("https://discord.com/api/v8/guilds/948626665716727869/channels").then(response => {
    //         console.log('guilds/948626665716727869/channels', response.data)
    //     })
    //     axios.get("https://discord.com/api/v8/guilds/948626665716727869/roles").then(response => {
    //         console.log('guilds/948626665716727869/roles', response.data)
    //     })
    // }, [])
    
    return <div className="welcome-party-root" style={{width: '100%', height: '100%', position: 'fixed', top: 0, left: 0, display: 'flex', flexDirection: 'column'}} >
        <WelcomePartyTopBar userInfo={userInfo}/>
        <NeedHelpBottomLeft/>
        <div style={{ flex: 1, width: '100%', minHeight: '0', position: 'relative' }}>
            <div className="ftux-steps-container" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: 740, height: '100%'}}>
                <div style={{ width: 700, margin: 20, marginTop: 0, marginBottom: 0,  height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', position: 'relative', marginTop: 30, marginBottom: 20 }} className={"ftux-steps-list " + (isHeaderCollapseOpen ? '' : 'closed')}>
                        <div style={{ position: 'absolute', top: 0, right: 0, transform: isHeaderCollapseOpen ? 'rotate(180deg)' : 'none', display: 'none' }}
                            onClick={() => setIsHeaderCollapseOpen(!isHeaderCollapseOpen)}
                            className="collapse-icon"
                            
                        >
                            <CollapseSvg/>
                        </div>
                        {STEPS_CONFIG.filter(x => x.stepLabel).map(({ stepLabel, stepTitle }, i) => {
                            const isEnabled = currentStepIndex >= i
                            const isCurrent = currentStepIndex === i
                            const stateColor = isEnabled ? '#5865F2' :'#777680'
                            const canGoToThisStep = !isCurrent && isEnabled || (currentStepIndex + 1 === i && isNextStepEnabled)
                            return <>
                                {i > 0 && <div className="separator" style={{ background: stateColor, height: 1, width: 80, marginTop: 10 }}/>}
                                <div className={"step-item " + (isCurrent ? 'current' : '')} style={{
                                    fontWeight: 600, 
                                    fontSize: 12, 
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    width: 70,
                                    margin: '0 10px',
                                    cursor: canGoToThisStep ? 'pointer' : 'default'
                                }} onClick={canGoToThisStep ? () => syncOnboardingObject(() => goToStep(i)) : null}>
                                    <div style={{ 
                                        background: stateColor, 
                                        borderRadius: '100%', 
                                        width: 20, height: 20,
                                        minWidth: 20, minHeight: 20
                                    }}>
                                        <div style={{position: 'relative', top: 3, color: 'white'}}>
                                            {i + 1}
                                        </div>
                                    </div>
                                    <b style={{marginTop: 5, fontWeight: isEnabled ? 600 : 400}}>{stepLabel}</b>
                                </div>
                            </>
                        })}
                    </div>

                    <div style={{ overflow: 'auto', width: '100%', height: '100%' }} className="step-body">
                        {isLoading ? <div style={{ justifyContent: 'center', display: 'flex', marginTop: 30 }}>
                            <BallTriangle
                                height="50"
                                width="50"
                                color='rgb(88, 101, 242)'
                                ariaLabel='loading'
                            />
                        </div> : <div style={{ marginTop: 30 }}>
                            {currentStep.stepComponent && <currentStep.stepComponent onboardingObject={onboardingObject} setOnboardingObject={setOnboardingObject} currentStep={currentStep} isFaq={isFaq}/>}
                        </div>}
                    </div>
                        

                    <div style={{ marginTop: 'auto' }}>
                        <div style={{display: 'flex', justifyContent: isPreviousStepEnabled || !isFtux ? 'space-between' : 'flex-end', marginTop: 20, marginBottom: 20}}>
                            {isPreviousStepEnabled && <button onClick={() => syncOnboardingObject(goToPreviousStep)}>Back</button>}
                            {!isPreviousStepEnabled && !isFtux && <button onClick={() => navigate("/welcome-party/dashboard")}>Go to Dashboard</button>}
                            {currentStepIndex < STEPS_CONFIG.length - 1 && <button disabled={!isNextStepEnabled} onClick={() => syncOnboardingObject(goToNextStep)}>Next Step</button>}
                            {currentStepIndex === STEPS_CONFIG.length - 1 && <button disabled={!isNextStepEnabled} onClick={() => syncOnboardingObject(() => {
                                setIsLoading(true)
                                axios.post(isFaq ? `/create-community-for-faq_bot/${onboardingObject.id}` : editedOnboardingObjectId ? `/onboarding/commit_edits/${onboardingObject.id}` : `/onboarding/create_flow_and_permanent_embed/${onboardingObject.id}`, {
                                    community_name: onboardingObject.guildName
                                }).then((response) => {
                                    if(response.data.success) {
                                        loadChannelName()
                                        setShowSuccessPopup(true)
                                    }
                                }).catch((error) => {
                                    Swal.fire(JSON.stringify(error.response.data))
                                })
                            })}>Complete Setup</button>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {showSuccessPopup && <div style={{ position: 'fixed', left: 0, right: 0, top: 0, bottom: 0, background: 'rgba(0,0,0,.1)' }} className="ftux-success-popup-bg">
            <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{backgroundImage: `url(${FtuxSuccessPopupBg})`, backgroundSize: 'cover', 
                             width: '640px', minWidth: '640px', height: '400px',
                             borderRadius: 5
                             }} className="ftux-success-popup">
                    <div style={{ margin: 40 }}>
                        <h3 style={{fontSize: 24, fontWeight: 500, textAlign: 'left', color: 'white', marginBottom: 10}}>
                            {editedOnboardingObjectId ? 'You have successfully updated onboarding flow' : isFtux ? 'You have successfully set up your account!' : 'You have successfully created new flow!'}
                        </h3>
                        <div style={{ display: 'flex' }}>
                            <div style={{ textAlign: 'left', marginTop: 30 }}>
                                <p style={{ fontSize: 16, fontWeight: 500, lineHeight: '24px', color: 'white', textAlign: 'left'}}>
                                    You have successfully added FAQ bot to the {onboardingObject.guildName}
                                </p>
                                <p style={{ fontSize: 16, fontWeight: 500, lineHeight: '24px', color: 'white', textAlign: 'left'}}>
                                    Visit the admin portal to add questions
                                </p>
                                <button style={{ background: '#5865F2', marginTop: 20 }} onClick={() => {
                                    if(!isFaq) {
                                        navigate('/welcome-party/dashboard')
                                    } else {
                                        window.location = `/${onboardingObject.slug}`
                                    }
                                }}>
                                    Go to the {isFaq ? 'admin portal' : 'dashboard'}
                                </button>
                            </div>
                            <div>
                                <img src={FtuxSuccessPopupCheckmark} width="262"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>}
        <WelcomePartyBottomBar/>
    </div>
}

export default WelcomePartyFtuxSteps;