

export function formatDate(date) {
    return (date.getMonth() + 1) + '/' + date.getDate() + '/' +  (date.getFullYear() % 100);
}

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

export function formatDateWelcome(date) {
    return date.getDate() + ' ' + monthNames[date.getMonth()].slice(0, 3) + ' ' +  (date.getFullYear() % 100);
}