import { useEffect, useState } from "react";
import axios from '../../../utils/axios';

import ReactInterval from 'react-interval';

import IconCheckmarkSuccess from "../../../icons/checkmark-success.png"
import IconVideo from "../../../icons/video.png"

import { BallTriangle } from  'react-loader-spinner'

const FAQ_LINKS = [
    {
        title: 'How do I add the bot to server?',
        url: 'https://www.landing.party/scout-install#add-bot-to-the-server'
    },
    {
        title: 'How do I give the bot the correct permission?',
        url: 'https://www.landing.party/scout-install#give-bot-right-permission'
    }
]


export const FtuxStep4LinkBot = ({ onboardingObject, setOnboardingObject, currentStep, isFaq }) => {
    const [isSuccess, setIsSuccess] = useState(false)
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const communityName = onboardingObject.guildName

    const BOT_INVITE_URL = process.env.REACT_APP_BOT_INVITE_URL

    useEffect(() => {
        checkSuccess()

        if(!communityName) {
            axios.get("https://discord.com/api/v8/users/@me/guilds").then(response => {
                for(const guild of response.data) {
                    if(guild.id == onboardingObject.guild_id) {
                        setOnboardingObject({
                            ...onboardingObject,
                            guildName: guild.name
                        })
                        break;
                    }
                }
            })
        }
    }, [])

    useEffect(() => {
        setOnboardingObject({
            ...onboardingObject,
            botLinked: isSuccess
        })
    }, [isSuccess])

    function checkSuccess() {
        setIsLoading(true)
        axios.get(`/onboarding/does_bot_have_expected_guild_permissions/${onboardingObject.id}${isFaq ? '?is_faq=1' : ''}`).then(response => {
            setIsSuccess(true)
            setIsLoading(false)
        }).catch((error) => {
            if(error.response.data.error)
                setError(error.response.data.error)
            setIsLoading(false)
        })
    }

    return <div style={{width: '100%', textAlign: 'left'}}>
        <ReactInterval timeout={3 * 1000} enabled={!isSuccess} callback={checkSuccess} />

        <h3 style={{fontSize: 20, fontWeight: 500, textAlign: 'left'}}>{currentStep.stepTitle}</h3>

        <p style={{ color: '#777680', fontSize: 14, lineHeight: '20px', fontWeight: 400, textAlign: 'left', fontFamily: 'Roboto', letterSpacing: '0.25px' }}>
            In order to use the application, you must correctly install the Scout Bot in <b>{communityName ? communityName : 'Loading...'}</b>.<br/>
            Make sure you give the bot the right permissions following the instructions below.
        </p>

        {!isSuccess ? <a style={{margin: 0, border: 'none'}} target="_blank" href={BOT_INVITE_URL}>
            {<button onClick={() => {}/*setIsSuccess(true)*/}>
                Install Scout bot
            </button>}
            {isLoading && <div style={{display: 'inline-flex', height: '100%', justifyContent: 'center', alignItems: 'center', position: 'relative', top: 5, left: 10}}>
                <BallTriangle
                height="20"
                width="20"
                color='rgb(88, 101, 242)'
                ariaLabel='loading'
                />
            </div>}
        </a> : <div>
            <div style={{ display: 'flex', fontSize: 16, fontWeight: 700, marginTop: 20 }}>
                <div>
                    <img width="18" src={IconCheckmarkSuccess}/>
                </div>
                <span style={{ marginLeft: 15}}>The bot has been successfully installed and it has all the right permissions</span>
            </div>
        </div>}

        {!isSuccess && error && <div style={{ marginTop: 10 }}>
            <b style={{ color: '#BA1A1A' }}>{error}</b>
        </div>}

        <hr style={{background: '#E3E1EC', border: 'none', height: 1, margin: '20px 0'}}/>

        <div style={{fontWeight: 500, fontSize: 16, textAlign: 'left'}}>
            FAQ
        </div>

        <div>
            {FAQ_LINKS.map(({ title, url }) => {
                return <a href={url} target="_blank" style={{ margin: 0, display: 'inline' }}>
                    <div style={{ display: 'flex', fontSize: 16, fontWeight: 700, marginTop: 15 }}>
                        <div>
                            <img width="24" src={IconVideo}/>
                        </div>
                        <span style={{ marginLeft: 5}}>{title}</span>
                    </div>
                </a>
            })}
        </div>
    </div>
}

export default FtuxStep4LinkBot